// Return the redirection link from the codeinjection code
const getRedirectUrl = (codeinjection_string: string) => {
  let url = codeinjection_string?.match(
    /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/gi
  )

  return url ? url[0] : null
}

export default getRedirectUrl
