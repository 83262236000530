import React, { useState } from "react"
import { Link, graphql } from "gatsby"
import chunk from "lodash/chunk"
import Layout from "../components/Layout"
import MetaData from "../components/meta"
import getRedirectUrl from "../utils/getRedirectUrl"

// Import Styles
import "../styles/press.scss"

// Import Images
import presskit__styleguide from "../images/press/styleguide-image.svg"
import presskit__logos from "../images/press/logos-image.svg"
// import presskit__images from "../images/press/press-kit-images/image.jpg"

const formatDate = (d: string) => {
  let date = new Date(d)
  return date
    .toLocaleDateString("en-GB", {
      day: "numeric",
      month: "long",
      year: "numeric",
    })
    .replace(/ /g, " ")
}

interface Post {
  node: {
    id: string
    title: string
    slug: string
    published_at: string
    feature_image: string
    featureImageSharp: {
      publicURL: string
    }
    codeinjection_head: string
    codeinjection_foot: string
  }
}

interface Props {
  data: {
    press_releases: {
      edges: Array<Post>
      pageInfo: any
    }
    news: {
      edges: Array<Post>
      pageInfo: any
    }
  }
}

const PressPage = ({ data, location }: Props) => {
  // Press releases
  const press_releases = data.press_releases.edges
  const press_chunks = chunk(press_releases, 6)
  const [renderedPressPosts, setRenderedPressPosts] = useState(
    press_releases.length > 0 ? press_chunks[0] : []
  )
  const [pressPagination, setPressPagination] = useState(0)
  const [pressSpinner, setPressSpinner] = useState(false)

  // News
  const news = data.news.edges
  const news_chunks = chunk(news, 6)
  const [renderedNews, setRenderedNews] = useState(
    news.length > 0 ? news_chunks[0] : []
  )
  const [newsPagination, setNewsPagination] = useState(0)
  const [newsSpinner, setNewsSpinner] = useState(false)

  const getMorePressReleases = () => {
    setPressSpinner(true)
    setRenderedPressPosts(
      renderedPressPosts.concat(press_chunks[pressPagination + 1])
    )
    setPressPagination(pressPagination + 1)
    setTimeout(() => {
      setPressSpinner(false)
    }, 100)
  }

  const getMoreNews = () => {
    setNewsSpinner(true)
    setRenderedNews(renderedNews.concat(news_chunks[newsPagination + 1]))
    setNewsPagination(newsPagination + 1)
    setTimeout(() => {
      setNewsSpinner(false)
    }, 100)
  }

  return (
    <Layout>
      <MetaData
        location={location}
        title="Press | Traefik Labs"
        description="Keep up with Traefik Labs by reading our recent press releases and news articles."
      />

      <div className="press-page">
        {/* Press Releases */}
        <div className="press-releases">
          <div className="wrapper-1200">
            <hr className="hr-tiny" />
            <h2>Press Releases</h2>

            <div className="press-releases__grid">
              <div className="press-releases__cards">
                {renderedPressPosts.map(({ node }) => (
                  <Link
                    to={`/press/${node.slug}/`}
                    className="press-release-card"
                    key={node.id}
                  >
                    <div className="press-release-card__date">
                      {node.published_at}
                    </div>
                    <h3 className="press-release-card__title">{node.title}</h3>

                    <div className="press-release-card__link link--with-arrow">
                      Read more
                    </div>
                  </Link>
                ))}
              </div>
            </div>

            {/* Load more */}
            <div className="press-releases__load-more">
              {!pressSpinner &&
                pressPagination !== press_chunks.length - 1 &&
                press_releases.length !== 0 && (
                  <button
                    type="button"
                    onClick={() => getMorePressReleases()}
                    className="button--primary button--extra-padding button--with-shadow"
                  >
                    See More Press Releases
                  </button>
                )}

              {/* Spinner */}
              <div
                className={
                  "spinner press-releases-spinner" +
                  (pressSpinner ? " active" : "")
                }
              >
                <div className="lds-ripple">
                  <div></div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Contact */}
        <div className="press-contact">
          <div className="wrapper-1200">
            <div className="email-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="13"
                viewBox="0 0 20 13"
              >
                <g fill="none" fillRule="evenodd">
                  <path d="M-2-5h24v24H-2z" />
                  <path
                    fill="#D2DCF6"
                    d="M19.997 1.973L20 2v9c0 1.102-.897 2-2 2H2c-1.103 0-2-.898-2-2V2c0-.008.003-.017.003-.026l9.47 5.877a1 1 0 0 0 1.054-.001zM18 .001c.352 0 .677.1.965.26L10 5.824 1.035.261c.287-.16.613-.26.965-.26z"
                  />
                </g>
              </svg>
            </div>
            <h3>Press Contact</h3>
            <a
              href="mailto:press@traefik.io"
              className="button--primary button--primary--lowercase "
            >
              press@traefik.io
            </a>
          </div>
        </div>

        {/* Traefik Labs In News */}
        <div className="press-page-news" id="containous-in-news">
          <div className="wrapper-1200">
            <hr className="hr-tiny" />
            <h2>Traefik in the news</h2>

            <div className="press-page-news__cards">
              {renderedNews.map(({ node }) => (
                <a
                  href={
                    getRedirectUrl(node.codeinjection_head)
                      ? getRedirectUrl(node.codeinjection_head)
                      : `/news/${node.slug}/`
                  }
                  key={node.id}
                  className="news-card"
                >
                  <div className="news-card__image">
                    <img
                      src={
                        node.featureImageSharp?.publicURL || node.feature_image
                      }
                      alt={node.title}
                    />
                  </div>

                  <div className="news-card__content">
                    <div className="news-card__date">{node.published_at}</div>
                    <h3 className="news-card__title">{node.title}</h3>
                    <div className="news-card__link link--with-arrow">
                      Read more
                    </div>
                  </div>
                </a>
              ))}
            </div>

            {/* Load more */}
            <div className="press-page-news__load-more">
              {!newsSpinner &&
                newsPagination !== news_chunks.length - 1 &&
                news.length !== 0 && (
                  <button
                    type="button"
                    className="button--primary button--with-shadow button--extra-padding"
                    onClick={() => getMoreNews()}
                  >
                    See more news
                  </button>
                )}

              {/* Spinner */}
              <div
                className={
                  "spinner news-feed-spinner" + (newsSpinner ? " active" : "")
                }
              >
                <div className="lds-ripple">
                  <div></div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Press Kit */}
        <div className="press-kit" id="press-kit">
          <div className="wrapper-1200">
            <hr className="hr-tiny" />
            <h2>Press Kit</h2>

            <div className="wrapper">
              <div className="press-kit-item">
                <div className="press-kit-item__wrapper">
                  <div className="image">
                    <img src={presskit__styleguide} alt="download styleguide" />
                  </div>
                  <a
                    href="/downloads/Traefik_Labs_Brand_Guidelines.pdf"
                    className="link--with-icon link--with-icon--no-animation"
                    download
                  >
                    Download Styleguide
                  </a>
                </div>
              </div>

              <div className="press-kit-item">
                <div className="press-kit-item__wrapper">
                  <div className="image">
                    <img src={presskit__logos} alt="download logos" />
                  </div>
                  <a
                    href="/downloads/TraefikLabs-Logo.zip"
                    className="link--with-icon link--with-icon--no-animation"
                    download
                  >
                    Download Logos
                  </a>
                </div>
              </div>

              {/* <div className="press-kit-item">
                <div className="press-kit-item__wrapper">
                  <div className="image">
                    <img src={presskit__images} alt="download images" />
                  </div>
                  <a
                    href="https://info.traefik.io/hubfs/Containous-Images.zip"
                    className="link--with-icon"
                    download
                  >
                    Download Images
                  </a>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default PressPage

export const query = graphql`
  query {
    press_releases: allGhostPost(
      filter: { primary_tag: { slug: { eq: "press" } } }
      sort: { order: DESC, fields: [published_at] }
    ) {
      edges {
        node {
          id
          title
          slug
          published_at: published_at(formatString: "DD MMMM YYYY")
        }
      }
    }

    news: allGhostPost(
      filter: { primary_tag: { slug: { eq: "news" } } }
      sort: { order: DESC, fields: [published_at] }
    ) {
      edges {
        node {
          id
          title
          slug
          published_at: published_at(formatString: "DD MMMM YYYY")
          feature_image
          featureImageSharp {
            publicURL
          }
          codeinjection_head
          codeinjection_foot
        }
      }
    }
  }
`
